body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: sans-serif;
}

.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}
.my-theme-overrides {
  font-family: sans-serif;
}

.my-theme-overrides .str-video__speaker-layout__wrapper {
  color: white;
}