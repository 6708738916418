// Variables

$spacer-lg                      : 3rem;
$spacer-sm                      : 1.5rem;

$layout-spacer-lg               : 3rem;
$layout-spacer-x                : 1.5rem;

$app-sidebar-width              : 280px;
$app-sidebar-width-collapsed    : 80px;

$logo-height                    : 36px;
$logo-width                     : 154px; //135px; --commented for new logo

$login-logo-height              : 100px;
$login-logo-width               : 300px;

$app-header-height              : 60px;
$app-session-footer-height      : 45px;
$video-tile-control-div-height  : 35px;

//Sessions

$shape-picker-width             : 100%;
$shape-picker-width-collapsed   : 5%;