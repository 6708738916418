.chat {
  display: flex;
  position: fixed;
  height: 83%;
  width: 83%;
}

.str-chat__channel-list {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 0;
  flex-shrink: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  transition: width 0.3s ease-out;
}

.str-chat__channel-list--open {
  width: 30%;
  position: fixed;
}

.str-chat__channel {
  flex: 1;
  min-width: 0;
}

.str-chat__main-panel {
  min-width: 0;
  flex: 1;
}

.str-chat__main-panel--thread-open {
  display: none;
}

.str-chat__thread {
  flex: 1;
  height: 100%;
  position: fixed;
  z-index: 1;
}

.str-chat__channel-header .str-chat__header-hamburger {
  width: 30px;
  height: 38px;
  padding: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: transparent;
}

.str-chat__channel-header .str-chat__header-hamburger:hover svg path {
  fill: var(--primary-color);
}

/* Responsive Fixes */
@media screen and (min-width: 768px) {
  .str-chat__channel-list {
    width: 30%;
    position: initial;
    z-index: 0;
  }

  .str-chat__thread {
    position: initial;
    z-index: 0;
  }

  .str-chat__channel-header .str-chat__header-hamburger {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .str-chat__main-panel {
    min-width: 0;
  }

  .str-chat__main-panel--thread-open {
    max-width: 55%;
    display: flex;
  }

  .str-chat__thread {
    max-width: 45%;
  }

  .str-chat__channel-header .str-chat__header-hamburger {
    display: none;
  }
}

.no-channel-message{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 1000%;
}


// popup
/* Plus Button */
.plus-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: #007bff;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 60px;
  background: #007bff;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

.plus-button:hover {
  background: #0056b3;
}

/* Popup */
.popup {
  position: fixed;
  top: 31%;
  left: 87%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 60%;
  height: auto;
  width: 340px;
}

/* Popup Content */
.popup-content {
  display: flex;
  flex-direction: column;
}

/* List Container */
.popup ul {
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  background: #f9f9f9;
}

/* List Items */
.popup ul li {
  display: flex;
  align-items: center;
  background: white;
  padding: 8px;
  margin: 5px 0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.popup ul li:hover {
  background: #eef7ff;
}

/* Checkbox */
.popup ul li input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.popup ul li input[type="checkbox"]:checked {
  background: #007bff;
  border-color: #007bff;
}

.popup ul li input[type="checkbox"]:checked::after {
  content: "✔";
  font-size: 12px;
  color: white;
  position: absolute;
  top: -1px;
  left: 0px;
}

/* Buttons */
.popup button {
  margin: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.popup button:first-of-type {
  background: #007bff;
  color: white;
}

.popup button:first-of-type:hover {
  background: #0056b3;
}

.popup button:last-of-type {
  background: #ccc;
}

.popup button:last-of-type:hover {
  background: #b3b3b3;
}
